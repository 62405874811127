import { Box, Button, DarkMode, LightMode, Stack } from '@chakra-ui/react';
import { Link } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import { Container } from '../components/Container';
import GradientText from '../components/GradientText/GradientText';
import { Page } from '../components/Page';
import Paragraph from '../components/Paragraph/Paragraph';
import { seo } from '../components/Seo';
import backgroundImage from '../images/404-background.webp';

export const Head = seo({ title: 'Not found' });

export default function NotFoundPage() {
  const intl = useIntl();

  return (
    <DarkMode>
      <Box position="fixed" top={0} left={0} width="100%" height="100%" overflow="hidden">
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          zIndex={-1}
          background={`url(${backgroundImage})`}
          backgroundSize="cover"
          backgroundPosition="center"
        >
          <Page>
            <Container pt={[2, 8]}>
              <Stack spacing={8} shouldWrapChildren>
                <LightMode>
                  <GradientText>{intl.formatMessage({ id: 'pages.404.title' })}</GradientText>
                </LightMode>
                <Stack spacing={2}>
                  <Paragraph>{intl.formatMessage({ id: 'pages.404.sorryMessage' })}</Paragraph>
                  <Button as={Link} to="/" variant="link" leftIcon={<HiArrowNarrowLeft fontSize="1.25rem" />}>
                    {intl.formatMessage({ id: 'pages.404.goHome' })}
                  </Button>
                </Stack>
              </Stack>
            </Container>
          </Page>
        </Box>
      </Box>
    </DarkMode>
  );
}
